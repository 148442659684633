import React, { FC, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { PigogoSmallHorizontalProduct } from 'components';
import { useIonRouter } from '@ionic/react';
import { Favourite } from '../../../../models/Favourite';
import { GenericRequest } from '../../../../models/GenericRequest';
import { Link } from 'react-router-dom';

interface Props {
  close: () => void;
  loading: boolean;
  allFavourites?: GenericRequest<Favourite[]>;
}

const FavContent: FC<Props> = ({ close, loading, allFavourites }) => {
  const route = useIonRouter();

  const favRef = useRef();

  const reset = () => {
    close();
  };

  const goToFavourite = () => {
    reset();
    route.push('/favourites');
  };

  const favouriteClick = (slugName: string) => {
    reset();
    route.push(`/${slugName}`);
  };

  return (
    <Box sx={{ padding: '32px 0px 32px 0px' }}>
      <Box
        className="overscroll"
        ref={favRef}
        sx={{
          maxHeight: '399px',
          overflow: 'auto',
          margin: '0px 16px 0px 32px',
        }}
      >
        <Box pb={2}>
          <Typography variant="body2" component="h3" color={'#313D53'}>
            Αγαπημένα καταστήματα ({allFavourites ? allFavourites.totalCount : 0})
          </Typography>
        </Box>
        <Box>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            {loading && [1, 1, 1, 1].map(() => <Box></Box>)}
            {allFavourites?.data.length &&
              allFavourites.data.map((store: Favourite, index: number) => {
                const { id = 0, shop = { id: 0, name: 'N/A', image: 'N/A', slug: null } } = store ? store : {};
                if (allFavourites?.data.length <= 3) {
                  return (
                    <Box key={index} width={'100%'} position="relative">
                      <Link className="absLink" to={shop.slug ? '/' + shop.slug.name : 'N/A'}></Link>
                      <PigogoSmallHorizontalProduct
                        key={id}
                        logo={shop.image}
                        name={shop.name}
                        onClick={() => favouriteClick(shop.slug ? '/' + shop.slug.name : 'N/A')}
                      />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={index} width={'100%'} pr={2} position="relative">
                      <Link className="absLink" to={shop.slug ? '/' + shop.slug.name : 'N/A'}></Link>
                      <PigogoSmallHorizontalProduct
                        key={id}
                        logo={shop.image}
                        name={shop.name}
                        onClick={() => favouriteClick(shop.slug ? '/' + shop.slug.name : 'N/A')}
                      />
                    </Box>
                  );
                }
              })}
          </Box>
        </Box>
        <Box style={{ paddingTop: 16 }}>
          <Link className="linkMob linkMob--blue" to={'/favourites'} onClick={() => goToFavourite()}>
            Δες όλα τα αγαπημένα
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default FavContent;
