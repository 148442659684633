import React, { FC } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { PigogoButton, theme } from 'components';
import AdBlockIcon from '../../../assets/png/adblockers.png';
import { ReactComponent as Chrome } from '../../../assets/svgs/searchApp/chromeWhite.svg';
import { ReactComponent as Firefox } from '../../../assets/svgs/searchApp/firefoxWhite.svg';
import { ReactComponent as Close } from '../../../assets/svgs/close.svg';
import { ReactComponent as Info } from '../../../assets/svgs/info.svg';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AdBlockerModal: FC<Props> = ({ open, handleClose }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <IonModal
      mode={'ios'}
      style={!smDown ? { '--height': 'fit-content', '--width': '480px', '--border-radius': '16px' } : undefined}
      isOpen={open}
      canDismiss={true}
      onDidDismiss={() => handleClose()}
    >
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            paddingTop: '12px',
            height: '48px',
            boxSizing: 'border-box',
            '--ion-safe-area-left': '-8px',
            '--ion-safe-area-right': '-8px',
          }}
        >
          <IconButton
            disableRipple
            className="iconBtn"
            sx={{
              position: 'absolute',
              right: 12,
              top: 0,
              padding: 0,
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </IonToolbar>
      </IonHeader>
      <Box className="overscroll innerPopup">
        <Box
          sx={{
            paddingInline: smDown ? '24px 12px' : '32px 20px',
            paddingBottom: smDown ? '8px' : '36px',
            paddingTop: smDown ? '0' : '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
            <img src={AdBlockIcon} width={'60%'} alt="" />
            <Box display={'flex'} flexDirection={'column'} gap={0.5} alignItems={'center'}>
              <Typography variant="h4" component="h2" textAlign="center" color={'#313D53'}>
                Σημαντική ενημέρωση
              </Typography>
              <Typography variant="body2MediumR" component="p" textAlign="center" color={'#313D53'}>
                Αν χρησιμοποιείς κάποιον adblocker, θα ήταν καλύτερο να τον αφαιρέσεις διότι η αγορά σου ενδέχεται να
                μην καταγραφεί στο Pigogo. <br />
              </Typography>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              gap={2}
              sx={{ backgroundColor: '#ECEFF4', borderRadius: '8px', p: 2 }}
            >
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  backgroundColor: '#ECEFF4',
                }}
              >
                <Info />
              </Box>
              <Typography variant="subtitle1SmallM" component="p" color={'#313D53'}>
                Οι adblockers επηρεάζουν τον κώδικα καταγραφής των αγορών στα sites των καταστημάτων. Για περισσότερες
                πληροφορίες, δες&nbsp;
                <a
                  href="/faq?id=8"
                  target="_blank"
                  style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '400' }}
                >
                  εδώ
                </a>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </IonModal>
  );
};

export default AdBlockerModal;
