import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.css';

/* Theme variables */
import './theme/variables.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'components';
import { Provider } from 'react-redux';
import store from './redux/store';
import TabRoutes from './routes/TabRoutes';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env['REACT_APP_GOOGLE_TAG_MANAGER_ID'] ?? 'GTM-PRVFG3WG',
};

async function detectAdBlock() {
  let userHasInstalled = false;
  const googleAdUrl = 'https://go.youbehero.com/js/showads.js?_=1730643906938';
  try {
    await fetch(new Request(googleAdUrl), { mode: 'no-cors' }).catch((_) => (userHasInstalled = true));
  } catch (e) {
    console.log(e);
    userHasInstalled = true;
  } finally {
    console.log(`AdBlock Enabled: ${userHasInstalled}`);
  }

  const _adAttribute = 'adheader';
  const div = document.createElement('div');
  div.setAttribute('id', _adAttribute);
  div.classList.add('ad', 'ads', 'adv', 'advert', 'advertisement', 'banner', 'banners');
  div.setAttribute(
    'style',
    'height:1px !important; min-height:1px !important; max-height:1px !important; background-color: transparent;',
  );

  document.getElementsByTagName('body')[0]?.appendChild(div);
  const _div = document.getElementById(_adAttribute);

  setTimeout(() => {
    if (_div && _div.offsetHeight == 1) {
      console.log(`AdBlock Enabled: ${false}`);
    } else {
      console.log(`AdBlock Enabled: ${true}`);
    }
  }, 2500);
}

setupIonicReact();

GoogleAuth.initialize({
  clientId:
    process.env['REACT_APP_GOOGLE_APP_ID'] ??
    '431194858554-hr0nqoh4kfc5bco9nvsc6lgvnal1vefj.apps.googleusercontent.com',
  scopes: ['profile', 'email'],
  grantOfflineAccess: true,
});

FacebookLogin.initialize({ appId: process.env['REACT_APP_FACEBOOK_APP_ID'] ?? '1691996891076590' });

TagManager.initialize(tagManagerArgs);

detectAdBlock();

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <IonApp>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <IonReactRouter>
              <TabRoutes />
            </IonReactRouter>
          </ThemeProvider>
        </Provider>
      </IonApp>
    </HelmetProvider>
  );
};

export default App;
