import React from 'react';
import { Fade, Popover, PopoverProps, styled } from '@mui/material';

const FavouritesPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    disableAutoFocus
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 150 }}
    sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    width: '305px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    maxHeight: '463px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    border: '1px solid #EAECEE',
    borderRadius: '0px 0px 8px 8px',
    transform: 'translateX(24px) !important',
  },
  '& .MuiBackdrop-root': {
    background: '#1D2532',
    opacity: '0.00 !important',
  },
}));

export default FavouritesPopover;
