import { Box, Typography, useMediaQuery } from '@mui/material';
import { IonContent, IonPage, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import Layout from '../components/Navigation/Layout';
import { PigogoButton, PigogoTextField, theme } from 'components';
import PigogoSymbolWrapper from '../components/PigogoSymbolWrapper';
import PigogoFooter from '../components/PigogoFooter';
import { msgValid } from '../utils/validation';
import Warning from '../assets/svgs/redWarning.svg';
import { useResetPasswordMutation } from '../redux/api/mutations/loginMutations';
import { setSnackBar, setStateSnackBar } from '../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import useQuery from '../hooks/useQuery';
import { setAccessToken, setRefreshToken, setSeenAlert, setSessionLoggedIn } from '../redux/slices/authenticationSlice';
import { Router } from 'workbox-routing';
import { useHistory } from 'react-router';
import { isWeb } from '../utils/device';
import { Helmet } from 'react-helmet-async';
import ErrorExpiredLink from '../components/Error/ErrorExpiredLink';
import backArrow from '../assets/svgs/back_arrow.svg';

interface Props {
  handleRedir?: () => void;
}

const ResetPasswordPage: FC<Props> = ({ handleRedir }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const web = isWeb(); //const web = false;

  const initialPassword = { new: '', confirm: '', touchedNew: false, touchedConfirm: false };

  const error = {
    new: 'Τουλάχιστον 6 χαρακτήρες',
    confirm: (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px 0 16px 0',
          marginTop: '8px',
          width: smDown ? 'calc(100vw + 72px)' : 'auto',
          marginLeft: smDown ? '-48px' : '0',
        }}
      >
        <img alt={'pigogoApp'} src={Warning} style={{ paddingRight: '16px' }} />
        <span style={{ fontSize: '16px', fontWeight: '500', lineHeight: '24px' }}>
          Οι νέοι κωδικοί πρόσβασης δεν ταυτίζονται.
        </span>
      </span>
    ),
    email: 'Το email δεν είναι σωστό.',
  };

  const router = useIonRouter();
  const dispatch = useDispatch();
  const params = useQuery();
  const [password, setPassword] = useState(initialPassword);
  const [resetPassword, result] = useResetPasswordMutation();
  const [reqError, setReqError] = useState(false);
  const [expiredError, setExpiredError] = useState(false);

  const email = params.get('email');
  const token = params.get('token');

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewDidEnter(() => {
    setTimeout(() => {
      history.action !== 'PUSH' &&
        contentRef.current &&
        contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    }, 10);
  });

  function handleScrollEnd(e: any) {
    history.replace(`${router.routeInfo.pathname}?${params}`, {
      state: { scrollY: e.target.detail.scrollTop },
    });
  }

  useEffect(() => {
    if (!result) return;
    const error = result.error as any;
    if (result.isError && error.status === 404) {
      setReqError(true);
    } else if (result.isError) {
      setExpiredError(true);
      //dispatch(setSnackBar({ value: true, msg: 'Το αίτημα ανάκτησης κώδικου απέτυχε!', severity: 'error' }));
    }
    if (result.isSuccess) {
      if (web) {
        if (result?.data) {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
          dispatch(setSeenAlert({ seenAlert: false }));
        } else {
          dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
        }
      } else {
        if (result?.data) {
          const { access_token, refresh_token } = result['data'];

          setTimeout(() => {
            dispatch(setAccessToken({ token: access_token }));
            dispatch(setRefreshToken({ refresh_token }));
          }, 200);
        }
      }
      if (handleRedir !== undefined) {
        handleRedir();
      }
      router.push('/');
    }
  }, [result]);

  const handleResetPassword = () => {
    resetPassword({
      email: email ? email : '',
      token: token ? token : '',
      password: password.new,
      confirmPassword: password.confirm,
    });
  };

  return (
    <IonPage>
      {location.pathname === '/reset-password' && (
        <Helmet>
          <title>Επαναφορά κωδικού | Pigogo</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        {!expiredError && (
          <Layout>
            <PigogoSymbolWrapper typeOfWrapper={'faq'}>
              <Box
                p={mdDown ? '32px 16px' : '80px 171px'}
                display="flex"
                flexDirection="column"
                gap={2}
                alignItems="center"
              >
                <Typography variant="smallB" color="#1D2532" component="h2">
                  Δημιουργία νέου κωδικού
                </Typography>
                <Typography variant="body2MediumL" color="#1D2532" component="p">
                  Συμπλήρωσε παρακάτω το email σου και τον νέο κωδικό για τον λογαριασμό σου.
                </Typography>
              </Box>
            </PigogoSymbolWrapper>
            <Box
              width={'100%'}
              pt={mdDown ? '40px' : '80px'}
              pb={mdDown ? '64px' : '160px'}
              px={mdDown ? '24px' : '0'}
              display="flex"
              justifyContent={mdDown ? 'flex-start' : 'center'}
            >
              <form onSubmit={handleResetPassword}>
                <Box width={smDown ? '327px' : '496px'} maxWidth={'100%'} display="flex" flexDirection="column" gap={2}>
                  <PigogoTextField
                    type={'email'}
                    value={email ? email : ''}
                    autoComplete="email"
                    label="Email"
                    error={reqError}
                    helperText={reqError ? error.email : ''}
                    disabled
                  />
                  <PigogoTextField
                    type={'password'}
                    value={password.new}
                    autoComplete="new-password"
                    onFocus={() => {
                      password.touchedNew = true;
                    }}
                    error={password.touchedNew && !msgValid(password.new)}
                    helperText={password.touchedNew && !msgValid(password.new) ? error.new : ''}
                    label="Νέος κωδικός"
                    setValue={(value: string) => setPassword({ ...password, new: value })}
                  />
                  <PigogoTextField
                    type={'password'}
                    value={password.confirm}
                    onFocus={() => {
                      password.touchedConfirm = true;
                    }}
                    error={password.touchedConfirm && password.new !== password.confirm}
                    helperText={password.touchedConfirm && password.new !== password.confirm ? error.confirm : ''}
                    label="Επανάληψη νέου κωδικού"
                    setValue={(value: string) => setPassword({ ...password, confirm: value })}
                  />
                  <Box display="flex" width={'327px'} maxWidth={'100%'}>
                    <PigogoButton
                      fullWidth
                      type={'submit'}
                      text={'Υποβολή'}
                      variation={'secondary'}
                      onClick={handleResetPassword}
                      disabled={
                        password.new !== password.confirm ||
                        password.new === '' ||
                        password.confirm === '' ||
                        !msgValid(password.new)
                      }
                      loading={result.isLoading}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
            <PigogoFooter />
          </Layout>
        )}
        {expiredError && (
          <Layout>
            <ErrorExpiredLink />
          </Layout>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ResetPasswordPage;
