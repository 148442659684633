import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import PigogoFooter from '../components/PigogoFooter';
import FavouriteContent from '../components/Favourite/FavouriteContent';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import Layout from '../components/Navigation/Layout';
import PigogoSymbolWrapper from '../components/PigogoSymbolWrapper';
import { ReactComponent as Favourite } from '../assets/svgs/illustrations/tips/heart.svg';
import { useFavouritesQuery, useLazyFavouritesQuery } from '../redux/api/queries/favouritesQuery';
import CustomContainer from '../components/CustomContainer';
import StartStepsAlert from '../components/Home/StartStepsAlert';
import { useAppSelector } from '../redux/hooks';
import { setSnackBar } from '../redux/slices/layoutSlice';
import { setFirstLogin } from '../redux/slices/authenticationSlice';
import { useDispatch } from 'react-redux';
import WalkthroughModal from '../components/Navigation/Modals/WalkthroughModal';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import backArrow from '../assets/svgs/back_arrow.svg';

const Favourites = () => {
  const route = useIonRouter();
  const dispatch = useDispatch();

  const desktop = isPlatform('desktop');
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [startStep, setStartStep] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(true);
  const [walkthrough, setWalkthrough] = useState<boolean>(false);

  const isFirst = useAppSelector((state) => state.authentication.data.firstLogin);

  const [getFavs, { data: favourites, isLoading: loadFavourites }] = useLazyFavouritesQuery();

  const [showCarousel, setShowCarousel] = useState<boolean>(false);

  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  function handleScrollEnd(e: any) {
    history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  useIonViewDidEnter(() => {
    setIsFocused(true);

    if (localStorage.getItem('tab-changed') === '1') {
      contentRef.current && contentRef.current.scrollToTop();
    } else {
      setTimeout(() => {
        history.action !== 'PUSH' &&
          contentRef.current &&
          contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
      }, 10);
    }
    localStorage.removeItem('tab-changed');

    if (isFirst) {
      setTimeout(() => {
        !tablet && setStartStep(true);
        !tablet && dispatch(setFirstLogin({ isFirst: false }));
      }, 7000);
    }
  });

  useIonViewWillLeave(() => {
    setIsFocused(false);
  });

  useIonViewDidEnter(async () => {
    contentRef.current && contentRef.current.scrollToTop();
    const favs = await getFavs({ limit: 16 }).unwrap();

    if (!favs?.totalCount) {
      setShowCarousel(true);
    } else {
      setShowCarousel(false);
    }
  });

  useIonViewDidLeave(async () => {
    await getFavs({ limit: 16 });
  });

  const getBottom = () => {
    if (favourites && favourites.data.length === 0) {
      return 32;
    }
    return 80;
  };

  const desktopSection = () => {
    return (
      <PigogoSymbolWrapper
        typeOfWrapper="favourites"
        maxHeight={favourites && favourites.data.length === 0 ? 384 : 'inherit'}
      >
        <Box textAlign={'center'} style={{ paddingBottom: getBottom(), paddingTop: 80 }}>
          <CustomContainer>
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'}>
                <Box>
                  <Typography variant={'smallB'} component="h2" color={'#1D2532'}>
                    Αγαπημένα καταστήματα
                  </Typography>
                </Box>
                <Box width={'608px'} maxWidth={'100%'}>
                  {loadFavourites && (
                    <Typography variant="body2MediumL" component="p" color={'#1D2532'}>
                      Δεν έχεις προσθέσει κάποιο κατάστημα στα αγαπημένα σου.
                    </Typography>
                  )}
                  {!loadFavourites && (
                    <Typography variant="body2MediumL" component="p" color={'#1D2532'}>
                      {favourites
                        ? favourites.data.length === 0
                          ? 'Δεν έχεις προσθέσει κάποιο κατάστημα στα αγαπημένα σου.'
                          : 'Διαχειρίσου τα αγαπημένα σου καταστήματα για να λαμβάνεις ειδοποιήσεις για νέες προσφορές ή κουπόνια καθώς και αυξήσεις στο ποσοστό επιστροφής τους!'
                        : 'Κάτι πήγε στραβά!'}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box>{favourites && favourites.data.length === 0 && <Favourite />}</Box>
            </Box>
          </CustomContainer>
        </Box>
      </PigogoSymbolWrapper>
    );
  };

  const mobileSection = () => {
    if (!favourites || loadFavourites) {
      return;
    }
    if (!loadFavourites && favourites.data.length === 0) {
      return (
        <CustomContainer>
          <Box display={'flex'} flexDirection={'column'} textAlign={'center'} gap={1}>
            <Box>
              <Favourite />
            </Box>

            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box>
                <Typography variant={'subtitle1'}>Δεν έχεις προσθέσει κάποιο κατάστημα στα αγαπημένα σου.</Typography>
              </Box>
              <Box>
                <Typography variant={'subtitle1SmallR'}>Ξεκίνα τώρα!</Typography>
              </Box>
            </Box>

            <Box paddingTop={2}>
              <PigogoButton
                fullWidth
                variation="secondary"
                text="Δες τα καταστήματα"
                onClick={() => route.push(`/stores`)}
              />
            </Box>
          </Box>
        </CustomContainer>
      );
    }
  };

  return (
    <IonPage>
      {(mobile || tablet) && (
        <IonHeader class="ion-no-border">
          <IonToolbar
            style={{
              '--background': '#FFFFFF',
              '--border-width': 0,
              paddingTop: '0 + --ion-safe-area-top',
            }}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
              <IonButtons slot="start">
                <IonBackButton
                  className="backBtn"
                  text=""
                  style={{
                    '--color': '#313D53',
                    '--padding-start': 0,
                    '--padding-end': 0,
                    '--icon-font-size': '24px',
                    '--border-radius': 0,
                    '--min-height': '24px',
                    '--min-width': '24px',
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    maxHeight: '24px',
                    display: 'inline-flex',
                  }}
                  icon={backArrow}
                  defaultHref={'/'}
                />
              </IonButtons>
              <Typography component="h2" variant="body2" color={'#313D53'}>
                Αγαπημένα
              </Typography>
              <Box minWidth={'24px'}></Box>
            </Box>
          </IonToolbar>
        </IonHeader>
      )}
      {location.pathname === '/favourites' && (
        <Helmet>
          <title>Αγαπημένα καταστήματα | Pigogo - Επιστροφή χρημάτων & κουπόνια</title>
          <meta
            name="description"
            content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
          />
        </Helmet>
      )}
      <IonContent scrollEvents={true} onIonScrollEnd={(e) => handleScrollEnd(e)} ref={contentRef}>
        <Layout>
          <>
            {mobile ? mobileSection() : desktopSection()}
            <FavouriteContent
              favourites={favourites ? favourites.data : []}
              loading={loadFavourites}
              showCarousel={showCarousel}
            />
            {!tablet && <PigogoFooter />}
          </>
        </Layout>
        {(mobile || tablet) && (
          <StartStepsAlert
            open={startStep}
            setOpen={setStartStep}
            title="Δες πόσο εύκολα μπορείς να κερδίζεις επιστροφή χρημάτων."
            actionButton={
              <PigogoButton
                fullWidth
                text="Ας ξεκινήσουμε"
                variation="white"
                onClick={() => {
                  setStartStep(false);
                  setWalkthrough(true);
                }}
              />
            }
          />
        )}
        {(mobile || tablet) && (
          <WalkthroughModal
            open={walkthrough}
            handleClose={() => {
              setWalkthrough(false);
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Favourites;
